@import "../../../module.variables.scss";

.Table {
  .Table__Body {
    .Name {
      color: #4680FF;
    }
    .status {
      font-size: 14px;
      padding: 5px 8px;
      border-radius: 24px;
      color: #ddd;
      background: rgba(221, 221, 221, 0.15);
      border: 1px solid rgba(221, 221, 221, 0.25);
      &.status--active {
          color: #56ca00;
          background: rgba(#56ca00, 0.15);
          border: 1px solid rgba(#56ca00, 0.25);
      }
      &.status--inactive {
          color: #A0A0A0;
          background: rgba(#A0A0A0, 0.15);
          border: 1px solid rgba(#A0A0A0, 0.25);
      }
    }
    .action {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      .action__edit {
        cursor: pointer;
        transition: 0.25s ease-out;
        -webkit-transition: 0.25s ease-out;
        -moz-transition: 0.25s ease-out;
        -ms-transition: 0.25s ease-out;
        -o-transition: 0.25s ease-out;
        &:hover {
          transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -o-transform: scale(1.1);
        }
      }
    }
  }
}

.Edit {
  min-width: 400px;
  .group {
    display: flex;
    justify-content: space-between;
  }
  .item {
    margin-bottom: 18px;
    .label {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .input {
    .react-datetime-picker__wrapper {
      border: 1px solid $input-border-color;
      border-radius: $borderRadius;
      background: $input-background-color;
    }
  }
  #active {
    .input {
      border: none;
    }
  }
  // .btnBan {
  //     background-color: #d99e0b;
  //     border: 1px solid #d99e0b;
  // }
}