@import '../../../module.variables.scss';
@import './components';
.PageFranchiseTable {
    .Table {
        .Table__Body {
            tr {
                td {
                    .user {
                        // &:hover {
                        //     text-decoration: underline;
                        // }
                        .user-clickable {
                            cursor: pointer;
                            color: #16b1ff;
                            text-decoration: underline;
                        }
                    }
                    .profit-volume-green {
                        background: rgba(86, 202, 0, 0.05);
                        color: #56ca00;
                    }
                    .profit-volume-red {
                        background: rgba(255, 76, 81, 0.05);
                        color: #ff4c51;
                    }
                    .level {
                        max-width: 48px;
                        width: 100%;
                    }
                    .met-clickable {
                        cursor: pointer;
                        color: #16b1ff;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .TabLinks {
        .nav-item {
            display: inline-flex;
            align-items: center;
            padding: 10px 25px;
            color: #8f9bb3;
            text-decoration: none;
            border-bottom: 2px solid rgba(70, 128, 255, 0);
            font-weight: 500;
            cursor: pointer;
            svg {
                margin-right: 8px;
                * {
                    fill: #8f9bb3;
                }
            }
            &.active {
                color: #4680FF;
                border-bottom-color: #4680FF;
                svg * {
                    fill: #4680FF;
                }
            }
        }
    }
    .title-bundle {
        margin-bottom: 8px;
    }
    .button-container {
        .Button.success {
            margin-right: 8px;
        }
    }
}