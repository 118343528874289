@import '../../../module.variables.scss';
.WithdrawProgress {
    .Table {
        .Table__Body {
            .status {
                font-size: 14px;
                padding: 6px 10px;
                border-radius: 24px;
                color: rgba(244, 244, 245, 0.9);
                &--just_created {
                    // color: #ff9d00;
                    background: $status-yellow;
                    // border: 1px solid rgba(255, 157, 0, 0.25);
                    // border: 1px solid #ff9d00;
                }
                &--success {
                    // color: #56ca00;
                    background: $status-green;
                    // border: 1px solid rgba(86, 202, 0, 0.25);
                    // border: 1px solid #56ca00;
                }
                &--rejected {
                    // color: #ff4c51;
                    background: $status-red;
                    // border: 1px solid rgba(255, 76, 81, 0.25);
                    // border: 1px solid #ff4c51;
                }
                &--processing {
                    // color: #0466c8;
                    background: $status-blue;
                    // border: 1px solid rgba(4, 102, 200, 0.25);
                    // border: 1px solid #ff4c51;
                }
            }
            .copy {
                display: flex;
                align-items: center;
                .copy__icon {
                    margin-right: 8px;
                    cursor: pointer;
                    svg {
                        width: 20px;
                        height: 20px;
                        * {
                            fill: $primary;
                        }
                    }
                }
                .copy__value {
                    color: $primary;
                }
            }
        }
    }
}
