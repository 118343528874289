.role__permission {
  &--item {
    box-shadow: 0 0 10px 8px #13203c;
    border-radius: 8px;
    padding: 12px 0;
  }

  &.row {
    margin: 0 !important;
  }
}
