.bridge-system-v2-page {
    .bridge-system-v2__price {
        border-radius: 5px;
        border: 1px solid rgba(70, 128, 255, 0.5);
        background: #0f192f;
        .col {
            padding: 0;
            &:not(:last-child) {
                border-right: 2px solid #222b45;
            }
            .bridge-system-v2__price__header {
                padding: 15px;
                text-align: center;
                border-bottom: 2px solid #222b45;
                font-size: 16px;
                font-weight: 700;
                color: #adb7be;
            }
            .bridge-system-v2__price__item {
                display: flex;
                justify-content: space-between;
                padding: 20px 15px;
                color: #adb7be;
            }
        }
    }
    .bridge-system-v2__order {
        .pnl {
            &.pnl--profit {
                background: rgba(86, 202, 0, 0.05);
                color: #56ca00;
            }
            &.pnl--loss {
                background: rgba(255, 76, 81, 0.05);
                color: #ff4c51;
            }
        }
    }
}