.slippage {
  #activeSwitch {
    width: fit-content;
    margin-bottom: 0;
    .input {
      border: none;
      .InputToggleSwitch {
        display: flex;
        justify-content: center;
        align-items: center;
        .label {
          margin-right: 8px;
        }
      }
    }
  }
}