@import "../../../module.variables.scss";
.TableFilterInputSelect {
  width: 100%;
  border-radius: $borderRadius;
  border: 1px solid $input-border-color;
  min-width: 200px;
  // z-index: 99999;
  .TableFilterInputSelect__control {
    border: none !important;
    box-shadow: none !important;
    height: 37px;
    background: $input-background-color;
    color: $white;
  }
  .TableFilterInputSelect__placeholder {
    color: $grey;
  }
  .TableFilterInputSelect__indicator-separator {
    background: $grey;
  }
  .TableFilterInputSelect__indicator {
    svg {
      * {
        fill: $grey;
      }
    }
  }
  .TableFilterInputSelect__single-value {
    color: $white;
  }
  .TableFilterInputSelect__input {
    input {
      color: $white !important;
    }
  }
  .TableFilterInputSelect__menu {
    z-index: 99999;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid $input-border-color;
    background: $input-background-color;
    .TableFilterInputSelect__menu-list {
      background: $input-background-color;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.75);
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      .TableFilterInputSelect__option {
        cursor: pointer;
        background: $input-background-color;
        overflow-x: hidden;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        -o-transition: 0.5s;
        &:not(:last-child) {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            height: 1px;
            width: 85%;
            border-bottom: 1px solid rgba(255, 255, 255, 0.08);
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
          }
        }
        &:hover {
          background: $input-border-color;
        }
      }
    }
  }
  .TableFilterInputSelect__option--is-selected {
    background: $primary;
    cursor: default;
    &:hover {
      background: $primary;
    }
  }
}
