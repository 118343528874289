@import '../../module.variables.scss';
.page-experience-game-list {
    .button-container {
        display: flex;
        justify-content: space-between;
        .button-group {
            .approve-button {
                margin-right: 16px;
            }
        }
        .infor-container {
            margin-bottom: 15px;
            display: flex;
            .user-participation {
                margin-right: 32px;
                display: flex;
                align-items: center;
                .user-participation__icon {
                    border-radius: 50%;
                    background: rgba(#199248, 0.25);
                    width: 36px;
                    height: 36px;
                    margin-right: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .user-participation__content {
                    color: #D0D0D0;
                    font-size: 28px;
                    display: flex;
                    .sub-text {
                        white-space: nowrap;
                        color: #199248;
                        font-size: 15px;
                        transform: translateY(5px);
                        -webkit-transform: translateY(5px);
                        -moz-transform: translateY(5px);
                        -ms-transform: translateY(5px);
                        -o-transform: translateY(5px);
                    }
                }
            }
            .total-package {
                display: flex;
                align-items: center;
                .total-package__icon {
                    border-radius: 50%;
                    background: rgba(#f3ba2f, 0.25);
                    width: 36px;
                    height: 36px;
                    margin-right: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .total-package__content {
                    color: #D0D0D0;
                    font-size: 28px;
                    display: flex;
                    .sub-text {
                        white-space: nowrap;
                        color: #f3ba2f;
                        font-size: 15px;
                        transform: translateY(5px);
                        -webkit-transform: translateY(5px);
                        -moz-transform: translateY(5px);
                        -ms-transform: translateY(5px);
                        -o-transform: translateY(5px);
                    }
                }
            }
        }
    }
    .Table {
        .Table__Body {
            .email {
                color: #4680FF;
            }
            .time-shift {
                padding: 4px 12px 4px 8px;
                // border-radius: 16px 0 0 16px;
                // -webkit-border-radius: 16px 0 0 16px;
                // -moz-border-radius: 16px 0 0 16px;
                // -ms-border-radius: 16px 0 0 16px;
                // -o-border-radius: 16px 0 0 16px;
                &.time-shift--10 {
                    color: #00b4d8;
                    border: 1px solid #00b4d8;
                    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(#00b4d8, 0.25) 50%, rgba(255, 255, 255, 0) 100%);
                }
                &.time-shift--15 {
                    color: #70e000;
                    border: 1px solid #70e000;
                    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(#70e000, 0.25) 50%, rgba(255, 255, 255, 0) 100%);
                }
                &.time-shift--20 {
                    color: #7b2cbf;
                    border: 1px solid #7b2cbf;
                    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(#7b2cbf, 0.25) 50%, rgba(255, 255, 255, 0) 100%);
                }
            }
            .experience-pack {
                border-radius: 16px;
                -webkit-border-radius: 16px;
                -moz-border-radius: 16px;
                -ms-border-radius: 16px;
                -o-border-radius: 16px;
                padding: 1px 5px;
                &.experience-pack--200 {
                    color: #e9c46a;
                    background: rgba(#e9c46a, 0.1);
                    border: 1px solid #e9c46a;
                }
                &.experience-pack--500 {
                    color: #f4a261;
                    background: rgba(#f4a261, 0.1);
                    border: 1px solid #f4a261;
                }
                &.experience-pack--1000 {
                    color: #ccff33;
                    background: rgba(#ccff33, 0.1);
                    border: 1px solid #ccff33;
                }
                &.experience-pack--2000 {
                    color: #9ef01a;
                    background: rgba(#9ef01a, 0.1);
                    border: 1px solid #9ef01a;
                }
                &.experience-pack--3000 {
                    color: #38b000;
                    background: rgba(#38b000, 0.1);
                    border: 1px solid #38b000;
                }
                &.experience-pack--4000 {
                    color: #008000;
                    background: rgba(#008000, 0.1);
                    border: 1px solid #008000;
                }
                &.experience-pack--5000 {
                    color: #006400;
                    background: rgba(#006400, 0.1);
                    border: 1px solid #006400;
                }
                &.experience-pack--8000 {
                    color: #004b23;
                    background: rgba(#004b23, 0.1);
                    border: 1px solid #004b23;
                }
                &.experience-pack--10000 {
                    color: #7b2cbf;
                    background: rgba(#7b2cbf, 0.1);
                    border: 1px solid #7b2cbf;
                }
                &.experience-pack--15000 {
                    color: #c55df6;
                    background: rgba(#c55df6, 0.1);
                    border: 1px solid #c55df6;
                }
                &.experience-pack--20000 {
                    color: #f20089;
                    background: rgba(#f20089, 0.1);
                    border: 1px solid #f20089;
                }
            }
            .status {
                font-size: 14px;
                padding: 5px 8px;
                border-radius: 24px;
                &--available {
                    color: #56ca00;
                    background: rgba(86, 202, 0, 0.15);
                    border: 1px solid rgba(86, 202, 0, 0.25);
                }
                &--expired {
                    color: #ff4c51;
                    background: rgba(255, 76, 81, 0.15);
                    border: 1px solid rgba(255, 76, 81, 0.25);
                }
            }
        }
    }
}