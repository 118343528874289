.App {
    .InputWraper {
        >.label {
            color: $white;
        }
        >.description {
            color: $grey;
        }
        >.wraper {
            >.input {
                input {
                    color: $white;
                    // background: transparent;
                }
                textarea {
                    color: $white;
                }
            }
        }
        &.disabled {
            >.wraper {
                .input {
                    border-color: transparent !important;
                    background: $offDark;
                    opacity: 0.5;
                    input {
                        // border: 1px solid $input-disabled-border-color;
                        background: transparent;
                        color: $input-disabled-text-color;
                    }
                    &:after {
                        cursor: default;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        // background: rgba($color: #000000, $alpha: 0.1);
                        border-radius: $borderRadius;
                    }
                }
            }
        }
    }
    // ============================ Table style ================================
    .Table {
        min-height: 150px;
        &.fixLayout {
            table {
                table-layout: fixed;
            }
        }
        table {
            thead {
                tr {
                    border-bottom: 2px solid $offDark;
                }
            }
            tbody {
                background: $main-background-color;
                box-shadow: none;
                border-radius: 0;
                border-bottom: 2px solid $offDark;
                tr {
                    border-bottom: 2px solid #0c1527;
                }
            }
            td {
                color: #adb7be;
            }
            tr {
                &.filter {
                    th {
                        padding-top: 10px;
                    }
                }
            }
        }
        .Message {
            background: transparent !important;
            box-shadow: none;
            border-radius: 0;
        }
        .fetching {
            background: transparent !important;
            border-radius: 0;
            svg.spinner {
                circle {
                    stroke: $white;
                }
            }
        }
    }
    // ============================ End Table style ============================
    // ============================ Alert style ================================
    &.Mobile {
        .Alert {
            padding: 0;
            max-width: 100%;
            width: calc(100% - 30px);
            .Alert__Item {
                margin-top: 15px;
                .icon {
                    width: max-content;
                    padding: 10px;
                    svg {
                        height: 25px;
                    }
                }
                .content {
                    padding: 10px 0;
                    padding-right: 15px;
                    .title {
                        font-size: 1em;
                        font-weight: 700;
                        margin-bottom: 0;
                    }
                    .message {
                        font-size: 1em;
                    }
                }
            }
        }
    }
    // ============================ End Alert style ============================
    // ============================ Button style ================================
    .Button {
        &.offDark-outline {
            @include btnOutlineColor($offDark);
        }
        &.grey-outline {
            @include btnOutlineColor($grey);
        }
    }
    // ============================ End Button style ============================
    // ============================ Message style ================================
    .Mobile {
        .Message {
            .content {
                word-break: break-word;
            }
        }
    }
    // ============================ End Message style ============================
}