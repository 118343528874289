@import "../../../module.variables.scss";
.TransactionList {
  min-height: 500px;
  .button-container {
    display: flex;
    justify-content: space-between;

    .infor-container {
      margin-bottom: 15px;
      display: flex;
      .total-amount {
        display: flex;
        align-items: center;
        .total-amount__icon {
          border-radius: 50%;
          background: rgba(#0466c8, 0.25);
          width: 28px;
          height: 28px;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (min-width: 1200px) {
            width: 36px;
            height: 36px;
          }
        }
        .total-amount__content {
          color: #d0d0d0;
          font-size: 22px;
          display: flex;
          align-items: center;
          @media (min-width: 1200px) {
            font-size: 28px;
          }
          .sub-text {
            white-space: nowrap;
            color: #0466c8;
            font-size: 13px;
            transform: translateY(5px);
            -webkit-transform: translateY(5px);
            -moz-transform: translateY(5px);
            -ms-transform: translateY(5px);
            -o-transform: translateY(5px);
            @media (min-width: 1200px) {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .Table__FilterBox {
    .List {
      .admin-checkbox {
        display: flex;
        align-items: center;
        height: 39px;
        border-radius: 5px;
        border: 1px solid rgba(70, 128, 255, 0.5);
        background: #13213e;
        padding: 0 8px;
        .label {
          color: #d3d3d3;
          font-size: 20px;
          margin-left: 8px;
          cursor: default;
        }
        .checkbox-compnt {
          .checkbox-compnt__icon {
            svg * {
              fill: #d3d3d3;
            }
          }
        }
      }
    }
  }
  .Table {
    .Table__Body {
      .coin {
        display: flex;
        align-items: center;
        .coin-label {
          font-weight: 700;
        }
        .coin-avatar {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }
      }
      .email {
        color: #4680ff;
      }
      .positive-value {
        background: rgba(86, 202, 0, 0.05);
        color: #56ca00;
      }
      .negative-value {
        background: rgba(255, 76, 81, 0.05);
        color: #ff4c51;
      }
    }
  }
}
