.intervention {
  .InputToggleSwitch {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .InputWraper {
    .wraper {
      .input {
        border: none;
        display: block;
        margin-left: auto;
      }
    }
  }

  .title {
    .InputWraper {
      margin: 0;
      .wraper {
        .input {
          border: none;
          display: block;
          margin-left: auto;
          width: fit-content;
        }
      }
    }
  }

  &--item {
  }
}
