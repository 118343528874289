@import '../../../module.variables.scss';
.PageFranchiseLevelSettingHistory {
    .Table {
        .Table__Body {
            .created-by {
                color: #4680FF;
            }
            .user-applied {
                color: #4680FF;
            }
            .level {
                max-width: 48px;
                width: 100%;
            }
        }
    }
}