@import "../../module.variables.scss";

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-radius: $borderRadius;
  outline: none;
  border: none;
  font-size: 1em;
  padding: 0 15px;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  z-index: 9999;

  &.dropdowns {
    background: rgba($color: $grey, $alpha: 0.5);
    color: $white;
    position: relative;

    .info {
      display: flex;
      text-align: left;
      flex-direction: column;

      .label {
        font-size: 0.8em;
      }

      .amount {
        font-weight: 600;
      }
    }

    .toggleIcon {
      margin-left: 20px;

      svg {
        height: 5px;
        transform: translateY(-2px);

        * {
          fill: $white;
        }
      }
    }

    .options {
      position: absolute;
      top: 100%;
      left: 0;
      padding-top: 10px;
      transform: scaleY(0);
      transform-origin: 50% 0%;
      transition: 0.2s ease;

      .wraper {
        background: $dark;
        width: max-content;
        border-radius: $borderRadius;
        border: 1px solid $borderColor;

        .item {
          text-align: left;
          padding: 10px 15px;

          .type {
            margin-bottom: 5px;
            color: $grey;
            font-size: 0.8em;
          }

          .amount {
            font-size: 1.2em;
            font-weight: 600;
          }

          &.active {
            cursor: default;

            .type,
            .amount,
            .name {
              color: $primary;
            }

            .amount {
              &::before {
                content: "• ";
              }
            }
          }
        }
      }
    }

    &:hover {
      .options {
        transform: scaleY(1);
      }
    }
  }
}
