@import '../../../module.variables.scss';
.change-password-page {
    .change-password-page__container {
        background: #0f192f;
        max-width: 1200px;
        margin-top: 80px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid rgba(70,128,255,.5);
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        .form {
            .form-wrapper {
                .form__title {
                    font-size: 24px;
                    color: #fff;
                    text-align: center;
                    margin: 64px 0;
                }
                .form__input-group {
                    margin-left: auto;
                    margin-right: auto;
                    #currentPassword, #newPassword, #confirmNewPassword {
                        .label {
                            font-size: 16px;
                            margin-bottom: 6px;
                        }
                    }
                }
                .form__confirm-button {
                    margin-top: 32px;
                    margin-bottom: 32px;
                    text-align: center;
                }
            }
        }
        .cover-image {
            padding: 80px;
        }
    }
}