@import '../../../module.variables.scss';
.maintenance-config {
    margin-bottom: 192px;
    .InputWraper {
        .wraper {
            .input {
                background: #13213E;
            }
        }
    }
    .handle-loading {
        padding: 32px 0 0 16px;
    }
}