@import "../../module.variables.scss";
.bridge-system-v2-page {
  .bridge-system-v2__price {
    border-radius: 5px;
    border: 1px solid rgba(70, 128, 255, 0.5);
    background: #0f192f;
    .col {
      padding: 0;
      &:not(:last-child) {
        border-right: 2px solid #222b45;
      }
      .bridge-system-v2__price__header {
        padding: 15px;
        text-align: center;
        border-bottom: 2px solid #222b45;
        font-size: 16px;
        font-weight: 700;
        color: #adb7be;
      }
      .bridge-system-v2__price__item {
        display: flex;
        justify-content: space-between;
        padding: 20px 15px;
        color: #adb7be;
      }
    }
  }
  .bridge-system-v2__order {
    .button-container {
      display: flex;
      justify-content: space-between;
      .infor-container {
        margin-bottom: 15px;
        display: flex;
        .total-amount {
          display: flex;
          align-items: center;
          .total-amount__icon {
            border-radius: 50%;
            background: rgba(#0466c8, 0.25);
            width: 28px;
            height: 28px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (min-width: 1200px) {
              width: 36px;
              height: 36px;
            }
          }
          .total-amount__content {
            color: #d0d0d0;
            font-size: 22px;
            display: flex;
            align-items: center;
            @media (min-width: 1200px) {
              font-size: 28px;
            }
            .sub-text {
              white-space: nowrap;
              color: #0466c8;
              font-size: 13px;
              transform: translateY(5px);
              -webkit-transform: translateY(5px);
              -moz-transform: translateY(5px);
              -ms-transform: translateY(5px);
              -o-transform: translateY(5px);
              @media (min-width: 1200px) {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
    .pnl {
      &.pnl--profit {
        background: rgba(86, 202, 0, 0.05);
        color: #56ca00;
      }
      &.pnl--loss {
        background: rgba(255, 76, 81, 0.05);
        color: #ff4c51;
      }
    }
  }
}
