@import "../../../module.variables.scss";
.franchise-ticket-bonus {
  .button-container {
    display: flex;
    justify-content: space-between;
    .button-group {
      .approve-button {
        margin-right: 16px;
      }
    }
    .infor-container {
      margin-bottom: 15px;
      display: flex;
      justify-content: end;
      .total-amount {
        display: flex;
        align-items: center;
        .total-amount__icon {
          border-radius: 50%;
          background: rgba(#f3ba2f, 0.25);
          width: 36px;
          height: 36px;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .total-amount__content {
          color: #d0d0d0;
          font-size: 28px;
          display: flex;
          .sub-text {
            white-space: nowrap;
            color: #f3ba2f;
            font-size: 15px;
            transform: translateY(5px);
            -webkit-transform: translateY(5px);
            -moz-transform: translateY(5px);
            -ms-transform: translateY(5px);
            -o-transform: translateY(5px);
          }
        }
      }
    }
  }
  .Table {
    .Table__Body {
      .coin {
        display: flex;
        align-items: center;
        .coin-label {
          font-weight: 700;
        }
        .coin-avatar {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }
      }
      .value {
        white-space: nowrap;
        .email {
          color: #4680ff;
        }
      }
      .positive-value {
        background: rgba(86, 202, 0, 0.05);
        color: #56ca00;
      }
      .negative-value {
        background: rgba(255, 76, 81, 0.05);
        color: #ff4c51;
      }
      .level {
        width: 64px;
        height: 64px;
        max-width: initial;
      }
      .value {
        display: flex;
        align-items: center;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px;
          svg {
            height: 15px;
            padding-left: 5px;
            cursor: pointer;
            * {
              fill: #4680ff;
            }
          }
        }
      }
    }
  }
  .PopupWraper {
    z-index: 10000;
  }
}
