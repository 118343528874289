@import "../../../../module.variables.scss";

@keyframes currentDropletAnimate {
  0% {
    opacity: 1;
    transform: scale(1.1);
  }
  50% {
    opacity: 0.8;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1.1);
  }
}

#root {
  .ResultsPanel {
    margin-top: 15px;

    .head {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      justify-content: space-between;

      .content {
        display: flex;
        align-items: center;

        .title {
          color: $white;
          font-size: 1.1em;
        }

        .boxCount {
          padding: 2px 5px;
          border: 1px solid $grey;
          border-radius: $borderRadius;
          margin-left: 15px;
          font-size: 1em;

          &.up {
            color: $success;
            border-color: $success;
          }

          &.down {
            color: $danger;
            border-color: $danger;
          }

          &.balance {
            color: $info;
            border-color: $info;
          }
        }
      }
    }

    .resultRow {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
    }

    .boxResults {
      display: inline-flex;

      .col_right_123 {
        margin-right: 15px;
      }

      .column {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .dot {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: $grey;
          margin-right: 6px;
          margin-bottom: 6px;

          &.up {
            background: #2fc592;
          }

          &.down {
            background: #ee475d;
          }

          &.balance {
            background: $info;
          }

          &.isCurrent {
            background: $warning;
            animation: currentDropletAnimate 0.5s infinite ease;
          }

          &.necklace {
            background: transparent;

            &.up {
              border: 1px solid $success;

              &::after {
                background: $success;
              }
            }

            &.down {
              border: 1px solid $danger;

              &::after {
                background: $danger;
              }
            }

            &.balance {
              border: 1px solid $info;

              &::after {
                background: $info;
              }
            }

            &.prevented {
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                height: 1px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.Mobile {
  .ResultsPanel {
    margin-top: 25px;

    .head {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      justify-content: space-between;

      .content {
        display: flex;
        align-items: center;

        .title {
          color: $white;
          font-size: 0.9em;
        }

        .boxCount {
          padding: 2px 5px;
          border: 1px solid $grey;
          border-radius: $borderRadius;
          margin-left: 7px;
          font-size: 0.8em;

          &.up {
            color: $success;
            border-color: $success;
          }

          &.down {
            color: $danger;
            border-color: $danger;
          }
        }
      }

      .toggleView {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $borderColor;
        padding: 2px;

        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $grey;
          padding: 2px 5px;
          cursor: pointer;
          font-size: 0.8em;

          &:hover {
            color: $white;
          }

          &.active {
            background: $primary;
            color: $white;
            cursor: default;
          }
        }
      }
    }

    .boxResults {
      display: inline-flex;

      .column {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: $grey;
          margin-right: 4px;
          margin-bottom: 4px;

          &.up {
            background: $success;
          }

          &.down {
            background: $danger;
          }

          &.balance {
            background: $info;
          }

          &.isCurrent {
            background: $warning;
            animation: currentDropletAnimate 0.5s infinite ease;
          }

          &.necklace {
            background: transparent;

            &.up {
              border: 1px solid $success;

              &::after {
                background: $success;
              }
            }

            &.down {
              border: 1px solid $danger;

              &::after {
                background: $danger;
              }
            }

            &.balance {
              border: 1px solid $info;

              &::after {
                background: $info;
              }
            }

            &.prevented {
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                height: 1px;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .rowBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
