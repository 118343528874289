@import '../../../module.variables.scss';
.page-franchise-commission-setting {
    .button-container {
        display: flex;
        justify-content: space-between;
    }
    .Table {
        .Table__Body {
            .email {
                color: $primary;
            }
            .action {
                display: flex;
                .action__remove {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 8px;
                    cursor: pointer;
                    transition: 0.25s ease-out;
                    -webkit-transition: 0.25s ease-out;
                    -moz-transition: 0.25s ease-out;
                    -ms-transition: 0.25s ease-out;
                    -o-transition: 0.25s ease-out;
                    &:hover {
                        transform: scale(1.2);
                        -webkit-transform: scale(1.2);
                        -moz-transform: scale(1.2);
                        -ms-transform: scale(1.2);
                        -o-transform: scale(1.2);
                    }
                }
            }
        }
    }
}