.table-price-now {
  .max {
    color: #ff4c51;
  }
  .min {
    color: #56ca00;
  }

  &.Table table th,
  &.Table table td {
    border-right: 2px solid #222b45;
    text-align: center;
    &:last-child {
      border-right: none;
    }
  }
}
