.mec-dashboard {
    .Table {
        .Table__Body {
            tr {
                &:last-child {
                    td {
                        color: #04cdf9 !important;
                        background: rgba(4, 205, 249, 0.1) !important;
                    }
                }
            }
        }
    }
}