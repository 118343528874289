@import "../../module.variables";
$navigatorWidth: 110px;
.UserWraper {
    padding: 0px 0px 0px $navigatorWidth;
}

.Navigator {
    position: fixed;
    top: 0;
    left: 0;
    width: $navigatorWidth;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    overflow: auto;
    background: linear-gradient( 90deg, rgba(15, 25, 47, 1) 0%, rgba(21, 35, 65, 1) 100%);
    box-shadow: 0 1px 3px 0 rgba(54, 80, 138, 0.3);
    height: 100%;
    user-select: none;
    overflow-x: hidden;
    font-size: 14px;
    z-index: 99999;
    img.logo {
        width: 56px;
        margin: 25px auto;
        transform: translate(3px);
        -webkit-transform: translate(3px);
        -moz-transform: translate(3px);
        -ms-transform: translate(3px);
        -o-transform: translate(3px);
    }
    .menu {
        flex: 1;
        a {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            color: $grey;
            text-decoration: none;
            padding: 22px 25px 0;
            margin-bottom: 15px;
            transition: 0.25s ease-out;
            -webkit-transition: 0.25s ease-out;
            -moz-transition: 0.25s ease-out;
            -ms-transition: 0.25s ease-out;
            -o-transition: 0.25s ease-out;
            svg {
                width: 22px;
                margin-bottom: 10px;
                * {
                    fill: $grey;
                }
            }
            &.active {
                color: #4680ff;
                cursor: default;
                svg * {
                    fill: #4680ff;
                }
                &:hover {
                    color: #4680ff;
                    svg * {
                        fill: #4680ff;
                    }
                }
            }
            &:hover {
                color: #4680ff;
                transform: scale(1.1);
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
                svg * {
                    fill: #4680ff;
                }
            }
        }
    }
    .btnLogout {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $grey;
        text-decoration: none;
        padding: 25px 0;
        cursor: pointer;
        transition: 0.25s ease-out;
        -webkit-transition: 0.25s ease-out;
        -moz-transition: 0.25s ease-out;
        -ms-transition: 0.25s ease-out;
        -o-transition: 0.25s ease-out;
        svg {
            width: 22px;
            margin-bottom: 10px;
            * {
                fill: $grey;
            }
        }
        &:hover {
            color: #ff4c51;
            transform: scale(1.1);
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
            svg * {
                fill: #ff4c51;
            }
        }
    }
}