@import '../../../module.variables.scss';
.g4-search-page {
    .infor-container {
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-end;
        .total-amount {
            display: flex;
            align-items: center;
            .total-amount__icon {
                border-radius: 50%;
                background: rgba(#f3ba2f, 0.25);
                width: 36px;
                height: 36px;
                margin-right: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .total-amount__content {
                color: #d0d0d0;
                font-size: 28px;
                display: flex;
                .sub-text {
                    white-space: nowrap;
                    color: #f3ba2f;
                    font-size: 15px;
                    transform: translateY(5px);
                    -webkit-transform: translateY(5px);
                    -moz-transform: translateY(5px);
                    -ms-transform: translateY(5px);
                    -o-transform: translateY(5px);
                }
            }
        }
    }
    .Table {
        .Table__Body {
            .primary-column {
                color: $primary;
            }
        }
    }
}
