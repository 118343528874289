.table-challenge-root {
  position: relative;

  .title-merge-table {
    position: absolute;
    top: 45px;
    left: 30px;
    font-size: 16px;
    font-weight: 700;
    color: #adb7be;
    text-align: left;
    -webkit-user-select: none;
    user-select: none;
    z-index: 999;
  }

  .table-challenge {
    &.Table {
      padding: 0;
      table {
        thead {
          tr {
            border-bottom: 0 !important;

            th {
              text-align: center;
              border-right: 2px solid #222b45;
              border-bottom: 2px solid #222b45 !important;
              &:first-child {
                text-align: left;
                padding-left: 30px;
                border-bottom: 0 !important;
                visibility: hidden;
              }
            }
          }
        }

        tbody {
          tr {
            border-bottom: 0 !important;
            td {
              border-bottom: 2px solid #222b45 !important;
              border-right: 2px solid #222b45;

              &:first-child {
                padding-left: 30px;
              }

              &:last-child {
                border-right: 0;
              }
            }

            &:first-child {
              td {
                &:first-child {
                  border-top: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
