@import "../../../module.variables.scss";

.SystemReport {
    &__Head {
        display: flex;
        align-items: center;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 15px;

        .item {
            display: inline-flex;
            align-items: center;
            padding: 7px;
            border-radius: $borderRadius;
            background: rgba($color: $grey, $alpha: 0.2);
            margin-right: 15px;

            > .label {
                margin-right: 10px;
                color: $white;
            }

            .InputDateTimeRange {
                display: flex;
                align-items: center;

                .input {
                    margin-bottom: 0px !important;
                }
            }

            .input {
                min-width: 210px;
                background: $dark;
                border-radius: $borderRadius;
                margin-left: 10px;
                flex-direction: row;

                .label {
                    width: unset !important;
                }

                > * {
                    width: 100%;
                }

                .InputSelect__control {
                    height: 45px !important;
                }
            }
        }

        .InputSelect {
            background: $dark;
            min-width: 200px;
        }
    }

    &__DetailPopup {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.5);
        width: 100%;
        height: 100%;
        z-index: 800;
        overflow-y: auto;
        padding-bottom: 150px;

        .box {
            position: relative;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            width: 700px;
            background: $offDark;
            border-radius: $borderRadius;

            .boxTitle {
                background: $dark;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px;

                span {
                    font-size: 1.3em;
                }

                .btnClose {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 30px;
                    width: 30px;
                    opacity: 0.8;
                    cursor: pointer;

                    svg {
                        height: 15px;

                        * {
                            fill: $white;
                        }
                    }

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            .content {
                padding: 15px;

                table {
                    width: 100%;
                    border-collapse: collapse;
                    table-layout: fixed;

                    tbody {
                        tr {
                            td {
                                border-bottom: 1px solid rgba($color: $grey, $alpha: 0.2);
                                padding: 15px 0;

                                &:nth-child(1) {
                                    color: $white;
                                }
                            }

                            &:last-child {
                                td {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
 
    .Table {
        &__Head {
            th {
                background: rgba($color: $grey, $alpha: 0.2) !important;
                color: $grey;
            }
        }

        thead.Table__Head {
            th {
                background: rgba($color: $primary, $alpha: 1) !important;
                color: $dark;
            }
        }

        th,
        td {
            border: 1px solid $offDark;
        }

        tbody {
            tr:first-child {
                td {
                    color: $info !important;
                    background: rgba($color: $info, $alpha: 0.1) !important;
                }
            }
            .profit-volume-green {
                background: rgba(86, 202, 0, 0.05);
                color: #56ca00;
            }
            .profit-volume-red {
                background: rgba(255, 76, 81, 0.05);
                color: #ff4c51;
            }
        }

        // ============================ Sort style ================================
        .enableSort {
            padding-right: 18px;
            position: relative;
            cursor: pointer;

            &:before,
            &:after {
                opacity: 0;
                border: 4px solid transparent;
                content: "";
                display: block;
                height: 0;
                right: 5px;
                top: 50%;
                position: absolute;
                width: 0;
            }

            &:before {
                border-bottom-color: rgba($color: $dark, $alpha: 0.5);
                margin-top: -9px;
            }

            &:after {
                border-top-color: rgba($color: $dark, $alpha: 0.5);
                margin-top: 1px;
            }

            &.increase {
                &:before,
                &:after {
                    opacity: 1;
                }

                &:before {
                    border-bottom-color: $dark;
                }
            }

            &.descrease {
                &:before,
                &:after {
                    opacity: 1;
                }

                &:after {
                    border-top-color: $dark;
                }
            }
        }
        // ============================ End Sort style ============================
    }
}

.InputSelect {
    width: 100%;
    border-radius: $borderRadius;
    border: 1px solid $input-border-color;
    min-width: 150px;
    &.InputSelect--is-disabled {
        opacity: 0.5;
    }
    .InputSelect__control {
        border: none !important;
        box-shadow: none !important;
        height: 37px;
        background: $input-background-color;
        color: $white;
    }
    .InputSelect__placeholder {
        color: $grey;
    }
    .InputSelect__indicator-separator {
        background: $grey;
    }
    .InputSelect__indicator {
        svg {
            * {
                fill: $grey;
            }
        }
    }
    .InputSelect__single-value {
        color: $white;
    }
    .InputSelect__input {
        input {
            color: $white !important;
        }
    }
    .InputSelect__menu {
        z-index: 10;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border: 1px solid $input-border-color;
        background: $input-background-color;
        .InputSelect__menu-list {
            background: $input-background-color;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.75);
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            .InputSelect__option {
                cursor: pointer;
                background: $input-background-color;
                overflow-x: hidden;
                transition: 0.5s;
                -webkit-transition: 0.5s;
                -moz-transition: 0.5s;
                -ms-transition: 0.5s;
                -o-transition: 0.5s;
                &:not(:last-child) {
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        height: 1px;
                        // width: 100%;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.08);
                        transform: translateX(-50%);
                        -webkit-transform: translateX(-50%);
                        -moz-transform: translateX(-50%);
                        -ms-transform: translateX(-50%);
                        -o-transform: translateX(-50%);
                    }
                }
                &:hover {
                    background: $input-border-color;
                }
            }
        }
    }
    .InputSelect__option--is-selected {
        background: $primary;
        cursor: default;
        &:hover {
            background: $primary;
        }
    }
}
