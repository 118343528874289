@import '../../module.variables.scss';
.atm-page {
    .main-title {
        font-size: 1.875rem;
        font-weight: 700;
        color: #fff;
        margin-bottom: 16px;
        margin-top: 8px;
    }
    .atm {
        // background: #0f192f;
        // border: 1px solid rgba(70, 128, 255, 0.5);
        // border-radius: 22px;
        .Table {
            .Table__Body {
                .email {
                    color: #4680FF;
                }
                .name {
                    font-size: 18px;
                    font-weight: 700;
                }
                .ticket {
                    font-size: 18px;
                    font-weight: 700;
                }
                .account-amount {
                    display: flex;
                    align-items: center;
                    .account-amount__amount {
                        font-size: 18px;
                        font-weight: 700;
                    }
                    .account-amount__icon {
                        margin-left: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: 0.25s;
                        cursor: pointer;
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
        .full-calendar {
            // margin: 24px;
            background: #02142b;
            padding: 12px;
            border-radius: 8px;
            border: 1px solid hsla(0,0%,100%,.1);
            .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
                .fc-view-harness {
                    .fc-dayGridMonth-view {
                        table.fc-scrollgrid {
                            border: none;
                            thead {
                                tr {
                                    th {
                                        border: none;
                                        .fc-scrollgrid-sync-inner {
                                            .fc-col-header-cell-cushion {
                                                font-size: 20px;
                                                color: #fff;
                                                &:hover {
                                                    text-decoration: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            tbody {
                                background-color: initial;
                                tr {
                                    td {
                                        border: none;
                                        padding: 4px;
                                        .fc-scroller-harness {
                                            .fc-scroller {
                                                .fc-daygrid-body {
                                                    table {
                                                        tbody {
                                                            tr {
                                                                td.fc-day {
                                                                    &.fc-day-past {
                                                                        opacity: 0.25;
                                                                    }
                                                                    &.fc-day-today {
                                                                        // filter: brightness(0.65);
                                                                        background-color: initial;
                                                                        .day-cell-item__item-1 {
                                                                            color: #bf0603!important;
                                                                        }
                                                                    }
                                                                    .fc-daygrid-day-frame {
                                                                        background: #606060;
                                                                        border-radius: 6px;
                                                                        transition: 0.25s;
                                                                        &:hover {
                                                                            filter: brightness(1.5);
                                                                        }
                                                                        .fc-daygrid-day-top {
                                                                            opacity: 1;
                                                                            .fc-daygrid-day-number {
                                                                                flex-direction: initial;
                                                                                color: #000;
                                                                            }
                                                                        }
                                                                        .fc-daygrid-day-events {}
                                                                        .fc-daygrid-day-bg {
                                                                            .fc-highlight {
                                                                                border-radius: 6px;
                                                                            }
                                                                            .fc-event {
                                                                                opacity: 1;
                                                                                border-radius: 6px;
                                                                                .day-cell-item {
                                                                                    display: flex;
                                                                                    flex-direction: column;
                                                                                    justify-content: center;
                                                                                    height: 100%;
                                                                                    .day-cell-item__item-1 {
                                                                                        text-align: center;
                                                                                        color: #000;
                                                                                        font-size: 14px;
                                                                                        font-weight: 700;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .Table {
        .Table__Body {
            .email {
                color: #4680FF;
            }
        }
    }
    .PopupWraper {
        .box {
            max-width: initial;
            max-height: 90vh;
            height: max-content;
            overflow: auto;
        }
    }
}