.mec-trade-config {
  &-form {
    background: #02142b;
    padding: 20px 30px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid rgba(70, 128, 255, 0.5);
  }
}
