@import "../../../module.variables.scss";
.UserList {
    &.HideRangeTime {
        .InputDateTimeRange {
            display: none;
        }
    }
    .Table__FilterBox {
        .InputDateTimeRange {
            &.disabled {
                opacity: 0.25;
            }
        }
    }
    table {
        tbody {
            .status {
                font-size: 14px;
                padding: 5px 8px;
                border-radius: 24px;
                &.status--just_registered {
                    color: #4680ff;
                    background: rgba(#4680ff, 0.15);
                    border: 1px solid rgba(#4680ff, 0.25);
                }
                &.status--email_verified {
                    color: #11c15b;
                    background: rgba(#11c15b, 0.15);
                    border: 1px solid rgba(#11c15b, 0.25);
                }
                &.status--aff_required {
                    color: #ff9d00;
                    background: rgba(#ff9d00, 0.15);
                    border: 1px solid rgba(#ff9d00, 0.25);
                }
            }
            .action {
                display: flex;
                .action__edit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 8px;
                    cursor: pointer;
                    transition: 0.25s ease-out;
                    -webkit-transition: 0.25s ease-out;
                    -moz-transition: 0.25s ease-out;
                    -ms-transition: 0.25s ease-out;
                    -o-transition: 0.25s ease-out;
                    &:hover {
                        transform: scale(1.2);
                        -webkit-transform: scale(1.2);
                        -moz-transform: scale(1.2);
                        -ms-transform: scale(1.2);
                        -o-transform: scale(1.2);
                    }
                }
                .action__more {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: 0.25s ease-out;
                    -webkit-transition: 0.25s ease-out;
                    -moz-transition: 0.25s ease-out;
                    -ms-transition: 0.25s ease-out;
                    -o-transition: 0.25s ease-out;
                    &:hover {
                        transform: scale(1.2);
                        -webkit-transform: scale(1.2);
                        -moz-transform: scale(1.2);
                        -ms-transform: scale(1.2);
                        -o-transform: scale(1.2);
                    }
                }
            }
        }
    }
}